//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// InteractMenu - Ordering Application Main control and router
//                See appVersion below for current version - Main Version: 1
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Access omAPI at omapi.interactsuite.com  - see URI in CommonCode
// Issues:
// 1. Scanning with zxing is a problem - see https://scanbot.io/ for a commercial product
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom' // Superset of react-router
import './optiview9.css';
import './App.css';
import { appendScript,trace } from './CommonCode.js';
import Login from './Login';
import AppMain from './AppMain';
import ScannerPage from './ScannerPage'
import OrderPage from './OrderPage';
import CheckoutPage from './CheckoutPage';
import AboutPage from './AboutPage';
import ContactUs from './ContactUs';
import ProfilePage from './ProfilePage';
import ReceiptsPage from './ReceiptsPage';
import ManagerPage from './ManagerPage';
import LandingPage from './LandingPage';
import { QueryPing } from './CommonCode.js';
// Common Key Repository for the Session along with Common variables 
export const SessionInfo = {
  session: '',
  sequenceid: 0,
  CC: 0, // Signon Check Code
  logonName: '',
  message: '',
  isConfirmationOnly: false,
  isSubApp: false,
  logonToken: undefined,
  props: undefined,
  showMenu: false,
  gotoAutoPage: '',
  StoreLocationID: 0,
  CurrentTableID: 0,
  CurrentGuestID: 0,
  CurrentKeyID: '',
  CurrentTableName: '',
  CurrentSeat: '',
  PWC: '', // Password Control?  
  AppProductID: 270,      
  AppSiteID: 2000008,
  EntCookie: false,
  //------ Owner Information ---------------------------------------------
  // This can be hard coded or based on a combination of sign-on, domain/subdomain and parameter 
  registrationCode: "InteractSuite", // In EntityConfiguration - This is used to establish default AppEntityID 
  AppEntityID: 0, // Generally set to 0 unless for a specific AppEntity
  ex: '',
  LockAppEntityID: 0, // Note - setting this will only allow Users from LockAppEntityID - not implemented yet
  // Note - ApplicationConfiguration and EntityConfiguration determine the configuration for the Applicaiton
}

export default class App extends React.Component {
  constructor(props) {
    super(props);
    //-------------------------------------------------------
    // With Parms: https://interactmenu.com/?tableid=2&locationid=1&token=%27rwXvl7IzPpzMtm9t+YsGSHDyL5xa7StbwH7RsksFg8dpATO8lnPji8vWtH7ROq2ohOT/u1b6Rnr2KEYg1oDXKipO4zJBTHMj0RLfSgEgrKG9PMqCQ+LA/2MUro5pke4EMnYwuLFx2vKe7lvBAmE9nzwlUqS1xfO9t6Sd+GwM1OQ=%27#logon40004
    //             https://interactmenu.com/?tableid=2&locationid=1&token=PPuTcnHwr4A+gZxF1e71UtyvK/6IZDZMMgXgTl+HfTa8yoYSHhy3CAJPlgnyDEIP2itOoSywsJfa0mWwO/El+dAezyQrtEbFi0gc7aQY4KgfcuZsJWYL+3AvKTBE8VfM#logon40004
    https://interactmenu.com/?tableid=2&locationid=1&token=xx#logon40004
    // qXjk/PT0NOxKDd9Jnss8qOfQL7T8fbbXFJ5QmD5dbIQPSCCobNaOu6DHpshUkzuLIj4bS9YPRuk+gdRc9AObrzuhUFYxcqfnHRtxXXiaSFmFPFmTOJ5IIdpWhZJlCX9E
    //
    // Parameters (?): tableid-pkey, locationid-pkey, token-encrypt, guestid-userid, seat-seatname, PWC-Password Change Control Token,RC-registrationCode, entity-AppEntityID
    // Control hash (#): #register10002-register return, #token20001-registerToken, logon40004-regular logon,
    //                         
    SessionInfo.hostname = window.location.hostname; // Domain Name
    trace(`Interact Menu - URL Props: ${JSON.stringify(props)}, pathname: ${window.location.pathname}`);
    if (window.location.pathname != '/') {
      trace('Set Pathname');
      window.location.pathname = '/';
    }
    SessionInfo.pathname = window.location.pathname;
    SessionInfo.host = window.location.host;
    SessionInfo.search = window.location.search;
    if (SessionInfo.search) {
      trace(`--> Parse URL Parameters in Menu - search: ${window.location.search}  hash: ${window.location.hash}`);
      this.callParms = this.parseQuery(window.location.search); // Get Rid of this function! It fucks up the token
      //let parmsStr = "{" + window.location.search.substr(1).replace(/&/g, ",").replace(/=/g, ":") + "}";
      //trace(`parmsStr: ${parmsStr}`);
      //let parms = this.parseQuery()
      //trace(`parms: ${JSON.stringify(this.callParms)}`);
      trace(`callparams: ${JSON.stringify(this.callParms)}`);
      if (this.callParms.locationid)
        SessionInfo.StoreLocationID = parseInt(this.callParms.locationid);
      if (this.callParms.tableid)
        SessionInfo.CurrentTableID = parseInt(this.callParms.tableid);
      if (this.callParms.guestid)
        SessionInfo.CurrentGuestID = parseInt(this.callParms.guestid);
      if (this.callParms.seat)
        SessionInfo.CurrentSeat = this.callParms.seat;
      if (this.callParms.PWC)
        SessionInfo.PWC = this.callParms.PWC;
      if (this.callParms.ex) {
        SessionInfo.ex = this.callParms.ex;
      } else if (this.callParms.entity)
        SessionInfo.AppEntityID = parseInt(this.callParms.entity);
      if (this.callParms.RC)
        SessionInfo.registrationCode = this.callParms.RC;
      trace(`Location: ${SessionInfo.StoreLocationID}, TableID: ${SessionInfo.CurrentTableID}, GuestID: ${SessionInfo.CurrentGuestID}, AppEntityID: ${SessionInfo.AppEntityID} Seat: '${SessionInfo.CurrentSeat}'`);
      if (this.callParms.token) {
        let token = this.callParms.token;
        let lth = token.length;
        if (token.startsWith("'") === true) {
          token = token.substr(1);
          lth--;
        }
        //trace(`Check = ${token}, endsWith ${token.endsWith("=")}`);
        //if (token.endsWith("'") === true)
        //  token = token.substr(0, lth - 1);
        //else if (token.endsWith("=") === true) {
        //  if (token.endsWith("=="))
        //    token = token.substr(0, lth - 1);
        //} else {
        //  trace(`Add = to token: ${token}`);
        //  token = token + "=";
        //}
        SessionInfo.logonToken = token;
        trace(`logonToken: ${SessionInfo.logonToken}, lth: ${lth}`);
      }
    }
    else { // For multi-tenant using parm AppEntityID - go to simple web screen if parameters not provided
      trace(`NO URL Parameters`);
      //SessionInfo.AppEntityID = 0; // Commented out 16May24
    }
    //const appEntityCode = config.get("AppSetting","3000906");
    //console.log(`AppSetting: ${appEntityCode}`);
    SessionInfo.width = window.innerWidth;
    let swidth = window.innerWidth;
    if (swidth > 700)
      swidth = 700;
    document.documentElement.style.setProperty('--screen-width',swidth + 'px');
    SessionInfo.height = window.innerHeight; // Window Height, Screen Height
    document.documentElement.style.setProperty('--screen-height',window.innerHeight + 'px');
    // href, hostname, protocol, port, assign() - will assign a new document 
    SessionInfo.href = window.location.href; // 15May23 - this is the paremeters passed in
    let ix = SessionInfo.href.indexOf("?");
    if (ix > 0)
      SessionInfo.href = SessionInfo.href.substring(0,ix);
    SessionInfo.protocol = window.location.protocol;
    trace(`href: ${SessionInfo.href}, hostname: ${SessionInfo.hostname}, protocol: ${SessionInfo.protocol}, entity: ${SessionInfo.AppEntityID}`);
    //if (window.location.pathname !== "/AppMain") {
    //  window.location.pathname = "/AppMain";
    //  trace(`Interact Menu - new pathname: ${window.location.pathname}`);
    //}
    SessionInfo.hash = window.location.hash;
    SessionInfo.registerToken = ''; // control token on link
    if (SessionInfo.hash === "#register10002")
      SessionInfo.isConfirmationOnly = true;
    else if (SessionInfo.hash === "#token20001")
      SessionInfo.registerToken = window.location.search;
    else if (SessionInfo.hash === "#logon40004") // Note - see 'With Parms' above
      SessionInfo.isSubApp = true; // Indicate SUB APP - in Iframe
    //----------------------------------------------------------------------------------------
    // https://interactmenu.com/?tableid=2&locationid=1
    // localhost:3000/?tableid=1&locationid=1
    // localhost:3001/?tableid=2&locationid=1
    //
    SessionInfo.session = '';
    SessionInfo.sequenceid = 100001; // Indicate Phone App - over 1000000 is Web App
    SessionInfo.StartDate = new Date();
    //SessionInfo.timeOffset = new Date().getTimezoneOffset(); // DefaultPST 
    SessionInfo.timeOffset = SessionInfo.StartDate.getTimezoneOffset(); // DefaultPST 
    SessionInfo.testScriptLoad = false;
    SessionInfo.traceDebug = true; // Turn on or off console tracing  
    // Package update: npm update --legacy-peer-deps
    //---- Debug/Production -------------------------------------------------------------------
    SessionInfo.debug = false; // 'true' connects to local server - false->production   
    SessionInfo.appVersion = 1.995  // November 15, 2024 - Barcode Scanner improvements
    //SessionInfo.appVersion = 1.994  // November 7, 2024 - Barcode Scanner improvements
    //SessionInfo.appVersion = 1.993  // October 31, 2024 - Grocery Scanner improvements
    //SessionInfo.appVersion = 1.992  // October 25, 2024 - Telerik 8.5.0 - Grocery Scanner
    //SessionInfo.appVersion = 1.991  // October 11, 2024 - Improve landing page detail and list, fix format issues
    //SessionInfo.appVersion = 1.987  // October 9, 2024 - Resolve problems with related popup and delete items
    //SessionInfo.appVersion = 1.9865  // October 4, 2024 - Resolve problems with dynamic sizing of popup
    //SessionInfo.appVersion = 1.9863  // October 3, 2024 - Significant performance improvement on detail load with options
    //SessionInfo.appVersion = 1.9861  // October 3, 2024 - Fixes to item detail with options on Edit - diagnose performance of detail popup - Telerik 8.4
    //SessionInfo.appVersion = 1.985  // October 2, 2024 - Fixes to second level popup and return - diagnose AppEntity/Domain issues
    //SessionInfo.appVersion = 1.984  // September 27, 2024 - Fixes to second level popup and return - proper saving of arrays
    //SessionInfo.appVersion = 1.983  // September 23, 2024 - Bug fixes on OrderPage and ItemDetail popup
    //SessionInfo.appVersion = 1.982  // September 20, 2024 - Item detail popup improvements, Phase 3 - Stage 3 of 4 - cleanup Payment Page and Receipts Page
    //SessionInfo.appVersion = 1.981  // September 17, 2024 - Finish item detail popup scrolling and non-scroll, Telerik 8.2 - Phase 3 - Stage 2 of 4 - cleanup Payment Page
    //SessionInfo.appVersion = 1.979  // September 12, 2024 - Rework of Item Detail with scrolling and fixed presentation - Phase 2 
    //SessionInfo.appVersion = 1.978  // August 30, 2024 - Cleanup of Checkout Page and Payment Popup - Phase 3 - Stage 1 of 4 - bug fixes in Order
    //SessionInfo.appVersion = 1.977  // August 22, 2024 - Cleanup of Item Detail Presentation - Phase 2 - Stage 4 of 4 - Totals - Start of Phase 3
    //SessionInfo.appVersion = 1.976  // August 15, 2024 - Cleanup of Item Detail Presentation - Phase 2 - Stage 3 of 4 - Options
    //SessionInfo.appVersion = 1.975  // August 14, 2024 - Cleanup of Item Detail Presentation - Phase 2 - Stage 2 of 4 - Layout
    //SessionInfo.appVersion = 1.974  // August 9, 2024 - Cleanup of Item Detail Presentation - Phase 2 - Stage 1 of 4 - Image and Top
    //SessionInfo.appVersion = 1.973  // August 7, 2024 - Telerik 8.2.2, Order Item Layout Improvements - Phase 2 of 4
    //SessionInfo.appVersion = 1.972 // August 1, 2024 - Reskinning and Layout improvements - Phase 1 of 4
    //SessionInfo.appVersion = 1.971 // July 26, 2024 - Reskinning and Layout improvements
    //SessionInfo.appVersion = 1.969 // July 17, 2024 - ApplicationConfiguration, CC Code on verify, Header overlay 
    //SessionInfo.appVersion = 1.968 // July 8, 2024 - Token Logon, Bambora NT00002 
    //SessionInfo.appVersion = 1.967 // July 5, 2024 - Cleanup Bambora payment and backend processing, Telerik 8.1, Fix bugs from Ottawa, OptiView9 
    //SessionInfo.appVersion = 1.966 // June 19, 2024 - autoFocus, remove navigate Location 
    //SessionInfo.appVersion = 1.965 // June 18, 2024 - Auto no-Logon with Customer creation and Management
    //SessionInfo.appVersion = 1.964 // June 14, 2024 - Auto no-logon, Phone Logon, Switch Customers to logon
    //SessionInfo.appVersion = 1.963 // June 6, 2024 - Format6, new colors
    //SessionInfo.appVersion = 1.962 // June 5, 2024 - Order Header, Branding of all Headers, Branding Color
    //SessionInfo.appVersion = 1.961 // May 30, 2024 - Clean up multiple orders and processing
    //SessionInfo.appVersion = 1.959 // May 29, 2024 - Pay multiple orders properly
    //SessionInfo.appVersion = 1.958 // May 28, 2024 - Payment Status Fix - Menu Formatting - Seats and Payments
    //SessionInfo.appVersion = 1.957 // May 27, 2024 - Fix Partial Payments & Google partial pay
    //SessionInfo.appVersion = 1.956 // May 24, 2024 - Partial Payments - Improved Payment Processing in OM
    //SessionInfo.appVersion = 1.955 // May 23, 2024 - Partial Payments, Proper payment processing, Cash Payments
    //SessionInfo.appVersion = 1.954 // May 22, 2024 - Icons fixed, cash payment, Order formatting, colouring
    //SessionInfo.appVersion = 1.954 // May 21, 2024 - OptiView8, Cash Payment (for testing)
    //SessionInfo.appVersion = 1.953; // May 17, 2024 - Diet Flag Upgrades and cleanup
    //SessionInfo.appVersion = 1.952; // May 16, 2024 - Improved phone login with AppEntityID based on URL - Update to Telerik 8.0.0
    //SessionInfo.appVersion = 1.951; // May 14, 2024 - Phone Login with Verify Code          
    //SessionInfo.appVersion = 1.949; // May 10, 2024 - Dietary Options Indicator - White Screen of Death Issue - TCP/IP call 135
    //SessionInfo.appVersion = 1.948; // May 7, 2024 - Updated Logon with domain name to determine AppEntityID
    //SessionInfo.appVersion = 1.947; // April 29, 2024 - Updated Payment Processing with multi-format
    //SessionInfo.appVersion = 1.946; // April 25, 2024 - Updated Payment Processing with multi-format
    //SessionInfo.appVersion = 1.945; // April 17, 2024 - Payment Processing and Google Pay
    //SessionInfo.appVersion = 1.944; // April 15, 2024 - Telerik 7.4.0
    //SessionInfo.appVersion = 1.943; // April 10, 2024 - Custom Credit Card input - improved CCV and Expiry Fields
    //SessionInfo.appVersion = 1.942; // April 9, 2024 - Custom Credit Card input - improved CCV and Expiry Fields
    //SessionInfo.appVersion = 1.941; // April 8, 2024 - Menu selections based on time & date - Inventory   
    //SessionInfo.appVersion = 1.939; // April 2, 2024 - Load Performance
    //SessionInfo.appVersion = 1.937; // March 22, 2024 - Small updates
    //SessionInfo.appVersion = 1.936; // March 19, 2024 - Font Type & Size Configuration - App Config - Telerik 7.3.0
    //SessionInfo.appVersion = 1.935; // March 13, 2024 - Cleanup of Formats 
    //SessionInfo.appVersion = 1.934; // March 12, 2024 - Cleanup of Formats - Change Menu Layout 2
    //SessionInfo.appVersion = 1.933; // March 7, 2024 - High Configuration Version with AppEntityID chooser - AppEntityID Cookies
    //SessionInfo.appVersion = 1.932; // March 7, 2024 - High Configuration Version with AppEntityID chooser - AppEntityID Cookies
    //SessionInfo.appVersion = 1.931; // February 29, 2024 - Telerik 7.2.3 - Restaurant Chooser - Configuration Cleanup - ex encrypted control access
    //SessionInfo.appVersion = 1.929; // February 22, 2024 - Telerik 7.2.1 - ApplicationTypeID - Add Tier Configuration
    //SessionInfo.appVersion = 1.928; // January 30, 2024 - Cash Pay Processing
    //SessionInfo.appVersion = 1.924; // January 30, 2024 - Cash Pay Processing
    //SessionInfo.appVersion = 1.923; // January 26, 2024 -  Update to 7.0.2 Telerik - Picture Taking
    //SessionInfo.appVersion = 1.922; // December 13, 2023 -  Update to 7.0.1 Telerik
    //SessionInfo.appVersion = 1.921; // December 12, 2023 - Delivery, Pickup, ProductItemType, Multi-Menu - Update to 7.0.1 Telerik
    //SessionInfo.appVersion = 1.914; // November 21, 2023 - Update Telerik 6.1.1, WaitList, Reservations
    //SessionInfo.appVersion = 1.913; // November 15, 2023 - Fogo Test Version - Change Password, Register
    //SessionInfo.appVersion = 1.912; // November 9, 2023 - Updated Demo version - Totalling bug fix 
    //SessionInfo.appVersion = 1.91; // November 7, 2023 - Update to Telerik 6.1.0 - Implement double column menu
    //SessionInfo.appVersion = 1.89; // October 16, 2023 - Minor bug fixes
    //SessionInfo.appVersion = 1.88; // October 3, 2023 - Minor bug fixes
    //SessionInfo.appVersion = 1.87; // September 28, 2023 - Update Telerik 5.19.0
    //SessionInfo.appVersion = 1.86; // September 20, 2023 - Update Telerik 5.18.0
    //SessionInfo.appVersion = 1.85; // September 15, 2023 - 4 Menu Styles, Diet Preferences
    //SessionInfo.appVersion = 1.84; // August 31, 2023 - Menu Control and Variable Pricing
    //SessionInfo.appVersion = 1.83; // August 21, 2023 - Demo Test - Telerik Updated - 5.16.1 - and React Updated - 18.2.0
    //SessionInfo.appVersion = 1.82; // August 3, 2023 - Fix bugs with Orders and Payments
    //SessionInfo.appVersion = 1.81; // August 2, 2023 - Fix bugs with Orders and Payments
    //SessionInfo.appVersion = 1.79; // August 1, 2023 - Fix bugs with Orders and Payments - and fix layout issues
    //SessionInfo.appVersion = 1.78; // July 31, 2023 - Fix bugs after demo - Layout improvements using css in Menu
    //SessionInfo.appVersion = 1.77; // July 27, 2023 - Resolve Safari problem with navigator.userAgentData.platform
    //SessionInfo.appVersion = 1.76; // July 26, 2023 - Fogo Branding orderitem detail popup and fetch
    //SessionInfo.appVersion = 1.75; // July 24, 2023 - Fogo Branding and Debug
    //SessionInfo.appVersion = 1.74; // July 20, 2023 - Fogo Update with Menu Layout 1-5 and overlapping divs
    //SessionInfo.appVersion = 1.73; // June 30, 2023 - Testing and bug fixing and clean up
    //SessionInfo.appVersion = 1.72; // June 12, 2023 - Product Option Fixes and Landing Page Fixes - Telerik Update 5.14.1
    //SessionInfo.appVersion = 1.71; // June 8, 2023 - Product Option Popup in OrderPage
    //SessionInfo.appVersion = 1.69; // June 5, 2023 - Profile Page, Password Change, Checkout Page Upgrade, Scheduler Fixes
    //SessionInfo.appVersion = 1.68; // May 18, 2023 - Registration Fixed
    //SessionInfo.appVersion = 1.67; // May 15, 2023 - Demo Preparation
    //SessionInfo.appVersion = 1.66; // May 12, 2023 - Bug resolution and reduced tracing
    //SessionInfo.appVersion = 1.65; // May 11, 2023 - Update index.js, Google Map, Landing Page buttons and Image sizing
    //SessionInfo.appVersion = 1.64; // May 5, 2023 - Table Reservations, Landing Page and Location improvements
    //SessionInfo.appVersion = 1.63; // May 1, 2023 - Update to 5.13.1 Telerik
    //SessionInfo.appVersion = 1.62; // April 20, 2023 - Landing Page, Locations, Location Detail, Messages
    //SessionInfo.appVersion = 1.61; // April 14, 2023 - Roles and Permissions, Pay After Tip, Manager Screen, Hours etc., Void, Credit Etc., QRCode Reader using ZXing
    //SessionInfo.appVersion = 1.59; // March 31, 2023 - Telerik Update 5.12.0, react router 6.9.0, optiview6.css - Remove MaterialUI! replace login & register - remove ReviewPayment, Menu Background Image
    //SessionInfo.appVersion = 1.56-1.58; // March 21, 2023 - Demo Version - Bug fixes
    //SessionInfo.appVersion = 1.55; // March 17, 2023 - Test Version for Menu Customization
    //SessionInfo.appVersion = 1.54; // March 15, 2023 - Test Version for Branding & customization
    //SessionInfo.appVersion = 1.53; // March 10, 2023 - Test Version for Branding & customization
    //SessionInfo.appVersion = 1.52; // March 1, 2023 - Update to latest React
    //SessionInfo.appVersion = 1.51; // February 16, 2023 - Update to version 5.11 of Telerik
    //SessionInfo.appVersion = 1.49;  // February 3, 2023 - Order Management
    //SessionInfo.appVersion = 1.48;  // January 26, 2023 - Order Management
    //SessionInfo.appVersion = 1.47;  // January 10, 2023 - Logo Download
    //SessionInfo.appVersion = 1.46;  // January 6, 2023 - Image crop, Code Clean, PCI Compliant Payment, Remove save credit cards
    //SessionInfo.appVersion = 1.45;  // December 19, 2022 - Cleanup
    //SessionInfo.appVersion = 1.44;  // December 1, 2022 - Copy Receipt to Current Order
    //SessionInfo.appVersion = 1.43;  // December 1, 2022 - Copy Receipt to Current Order
    //SessionInfo.appVersion = 1.42;  // November 30, 2022 - SeatName on Scan
    //SessionInfo.appVersion = 1.41;  // November 29, 2022 - Payment On Multiple Orders Errors, SeatName on QR Code, SeatName on multiple orders
    //SessionInfo.appVersion = 1.39;  // November 24, 2022 - Payment add failure, SeatName fixes, Order# fixes
    //SessionInfo.appVersion = 1.38;  // November 23, 2022 - Cookie update
    //SessionInfo.appVersion = 1.37;  // November 21, 2022 - Library Updates
    //SessionInfo.appVersion = 1.36;  // November 10, 2022 - Bug fixes, Location Fix
    //SessionInfo.appVersion = 1.35;  // November 9, 2022 - Updated to 5.9.0 Telerik
    //SessionInfo.appVersion = 1.34;  // October 25, 2022 - Improved detail popup - update to latest
    //SessionInfo.appVersion = 1.33;  // October 21, 2022 - Improved detail popup
    //SessionInfo.appVersion = 1.32;  // October 19, 2022 - Single token payment for no logon
    //SessionInfo.appVersion = 1.31;  // October 18, 2022 - Start of Bambora Payment Capture, Default Logon Payment Processing
    //SessionInfo.appVersion = 1.29;  // October 14, 2022 - Telerik 5.8 - performance improvements, popup item description
    //SessionInfo.appVersion = 1.28;  // September 20, 2022 - Security - update logon token, select bills to pay
    //SessionInfo.appVersion = 1.27;  // September 16, 2022 - Additional Telerik Update - List of bugs fixed - Related in OrderItems
    //SessionInfo.appVersion = 1.26;  // September 13, 2022 - Telerik Update - Help Replacement
    //SessionInfo.appVersion = 1.25;  // September 9, 2022 - LogOffReset changes, Monitor Changes
    //SessionInfo.appVersion = 1.25;  // September 8, 2022 - LogOffReset changes, Monitor Changes
    //SessionInfo.appVersion = 1.24;  // September 7, 2022 - LogOffReset changes
    //SessionInfo.appVersion = 1.23;  // September 6, 2022 - Bug fixes
    //SessionInfo.appVersion = 1.22;  // September 2, 2022 - Timeout Logoff (Note - somehow Order is missing)
    //SessionInfo.appVersion = 1.21;  // August 28, 2022 - No Logon Payment Hack
    //SessionInfo.appVersion = 1.19;  // August 27, 2022 - No Logon Payment Hack
    //SessionInfo.appVersion = 1.18;  // August 27, 2022 - Quick Add Fix
    //SessionInfo.appVersion = 1.17;  // August 26, 2022 - Bug fixes and consistency improvements, related items purchase status
    //SessionInfo.appVersion = 1.16;  // August 24, 2022 - Register, Login, Payment Processing, SeatName
    //SessionInfo.appVersion = 1.15;  // August 3, 2022 - Register, Payment Improvement
    //SessionInfo.appVersion = 1.14;  // July 29, 2022 - library updates and cleanup
    //SessionInfo.appVersion = 1.13;  // July 19, 2022 - Category Headings with picture, default logon control
    //SessionInfo.appVersion = 1.12;  // July 14, 2022 - Updated Google Login (Not)
    //SessionInfo.appVersion = 1.11;  // July 7, 2022 - Bug Fixes
    //SessionInfo.appVersion = 1.09;  // July 6, 2022 - Bug Fixes - Jeanne
    //SessionInfo.appVersion = 1.08;  // July 5, 2022 - Bug Fixes
    //SessionInfo.appVersion = 1.07;  // June 30, 2022 - Proceed with version 0.6.8 of react-scrolling
    //SessionInfo.appVersion = 1.06;  // June 29, 2022 - Issue fixes
    //SessionInfo.appVersion = 1.04;  // June 28, 2022 - Issue fixes
    //SessionInfo.appVersion = 1.03;  // June 27, 2022 - Issue fixes - About, Contact, Settings Page
    //SessionInfo.appVersion = 1.02;  // June 22, 2022 - Issue fixes
    //SessionInfo.appVersion = 1.01;  // June 21, 2022 - Issue fixes
    //SessionInfo.appVersion = 0.99;  // June 12, 2022 - Receipts and Use Past Order for new Order - plus order favorites
    //SessionInfo.appVersion = 0.98;  // June 8, 2022 - Improvements to UI - quick add, local search, promotion improvements
    //SessionInfo.appVersion = 0.97;  // June 6, 2022 - Bug Fixes related to last 2 versions, Signon Fixed
    //SessionInfo.appVersion = 0.96;  // May 30, 2022 - Horizontal Scrolling - Categories
    //SessionInfo.appVersion = 0.95;  // May 26, 2022 - Update to latest React 18.1 and React Router 6.3 and Telerik 5.3
    //SessionInfo.appVersion = 0.94;  // April 25, 2022 - Fix token logon
    //SessionInfo.appVersion = 0.93;  // April 20, 2022 - Fix Search - move state update to AFTER OM Fetch
    //SessionInfo.appVersion = 0.92;  // April 19, 2022 - Purchase Related Products, Promo's in Related, Cleanup
    //SessionInfo.appVersion = 0.91;  // April 15, 2022 - Performance, multi-seat bug fixes, state updates
    //SessionInfo.appVersion = 0.89;  // April 7, 2022 - remove compUpdate=false on componentdidmount - was preventing popup
    //SessionInfo.appVersion = 0.88;  // April 7, 2022 - Registration, Google Logon, Listview refresh reduction
    //SessionInfo.appVersion = 0.87;  // April 4, 2022 - Cleanup, Optimization
    //SessionInfo.appVersion = 0.86;  // April 1, 2022 - Cleanup, Optimization
    //SessionInfo.appVersion = 0.85;  // March 30, 2022 - Promotion Info, Related Item Display, Combo
    //SessionInfo.appVersion = 0.84;  // March 23, 2022 - Related items and Promotion Indicators
    //SessionInfo.appVersion = 0.83;  // March 17, 2022 - Seats improvement, prevent change to ordered, clean up, sort items, clean up scanner
    //SessionInfo.appVersion = 0.82;  // March 15, 2022 - Seats improvement, change update, general improvements
    //SessionInfo.appVersion = 0.81;  // March 11, 2022 - Option Update
    //SessionInfo.appVersion = 0.79;  // March 10, 2022 - Add, Change, Delete Option
    //SessionInfo.appVersion = 0.78;  // March 7, 2022 - Option Fix
    //SessionInfo.appVersion = 0.77;  // March 3, 2022 - Data Cleanup
    //SessionInfo.appVersion = 0.76;  // February 25, 2022 - Clean up of forms, buttons, etc.
    //SessionInfo.appVersion = 0.75;  // February 25, 2022 - Clean up of forms, buttons, etc.
    //SessionInfo.appVersion = 0.74;  // February 17, 2022 - Favorites, Selected, Formating, Picture Background, Icons, 404 Fix for IIS
    //SessionInfo.appVersion = 0.73;  // February 10, 2022 - Category Selection, Background image
    //SessionInfo.appVersion = 0.72;  // February 8, 2022 - Scrolling Categories, Search
    //SessionInfo.appVersion = 0.71;  // February 2, 2022 - Options improvement, promo pricing, Scrolling Categories
    //SessionInfo.appVersion = 0.69;  // February 2, 2022 - Options improvement, promo pricing, Scrolling Categories
    //SessionInfo.appVersion = 0.68;  // January 31, 2022 - Partial Payment Fixes
    //SessionInfo.appVersion = 0.67;  // January 28, 2022 - Payment summary, improvements to seat management & payment processing, autologon, token provided logon
    //SessionInfo.appVersion = 0.66;  // January 21, 2022 - Add Payment Methods, Payment bug fixes, Registration bug fixes
    //SessionInfo.appVersion = 0.65;  // January 20, 2022 - Registration + improvements to Form
    //SessionInfo.appVersion = 0.64;  // January 18, 2022 - Cookie - with saved token, location, updated Telerik and zebra
    //SessionInfo.appVersion = 0.63;  // January 7, 2022 - Clean up, Special Instructions, Mulitple payments (per seat)
    //SessionInfo.appVersion = 0.62;  // January 6, 2022 - Billing Information, Rating, Comment
    //SessionInfo.appVersion = 0.61;  // January 5, 2022 - Default Logon, Set current Seat
    //SessionInfo.appVersion = 0.59;  // December 31, 2021 - Add Seats
    //SessionInfo.appVersion = 0.58;  // December 21, 2021 - Parameters
    //SessionInfo.appVersion = 0.57;  // December 17, 2021 - Split Payments, Tip Processing, Payment Error Handling
    //SessionInfo.appVersion = 0.56;  // December 15, 2021 - Tables and Seats
    //SessionInfo.appVersion = 0.55;  // December 10, 2021 - Tables and Seats
    //SessionInfo.appVersion = 0.54;  // November 26, 2021 - ListView Header, Table Name and Seat
    //SessionInfo.appVersion = 0.53;  // November 25, 2021 - With Product List, Options & Checkout
    //SessionInfo.appVersion = 0.52;  // November 17, 2021 - With Product Lookup
    //SessionInfo.appVersion = 0.51;  // October 28, 2021 - Checkout Lite New Version
    //SessionInfo.appVersion = 0.34;  // June 8, 2020 - Checkout Completion
    //SessionInfo.appVersion = 0.33;  // June 1, 2020 - Checkout Lite Started
    // Server Access
    SessionInfo.URI = 'http://localhost:2000/api/omUI';  // Development - NOTE - on http and Port: 2000 - 8Feb20 - somehow the 2000 is getting translated to 43348?? 
    SessionInfo.Platform = 'D';
    if (!SessionInfo.debug) {
      //SessionInfo.URI = 'https://omapi.interactsuite.app:44343/api/omUI'; // Production  
      SessionInfo.URI = 'https://omapi.interactmenu.com:44343/api/omUI'; // Production 
      SessionInfo.Platform = 'P';
    }
    SessionInfo.Key = "XYZ" + "123";
    trace(`Interact Menu - Version: ${SessionInfo.appVersion}-${SessionInfo.Platform} - Start Date: ${SessionInfo.StartDate.toISOString()}, Entity: ${SessionInfo.AppEntityID}, Time Offset: ${SessionInfo.timeOffset}`);
    SessionInfo.commErrorCount = 0;
    // Control Header
    SessionInfo.popupNotifyOffset = { left: 20,top: 70 };
    SessionInfo.message = "";
    SessionInfo.notifyMessage = "";
    SessionInfo.notifyTime = 0;
    SessionInfo.pingCounter = 0;
    SessionInfo.notifyTimer = 0;
    SessionInfo.acknowledge = false;
    SessionInfo.SoundOn = false;
    SessionInfo.SoundOverride = false;
    SessionInfo.notifyRing = undefined;
    //SessionInfo.bellSound1 = new Audio(require("./images/bell1.wav"));
    //SessionInfo.bellSound2 = new Audio(require("./images/bell2.wav"));
    SessionInfo.bellSound1 = new Audio(require("./images/bell3.mp3"));
    SessionInfo.bellSound2 = SessionInfo.bellSound1;
    SessionInfo.bellSound3 = SessionInfo.bellSound1;
    SessionInfo.DocumentTypes = [{ ddName: "xxx",ddID: 0 },{ ddName: "jpg",ddID: 1 },{ ddName: "jpeg",ddID: 2 },{ ddName: "png",ddID: 3 },{ ddName: "gif",ddID: 4 },{ ddName: "webp",ddID: 5 }];
    SessionInfo.AppNotificationID = 0;
    SessionInfo.flagLevel = 0;  // Set but not used 
    SessionInfo.registerCounter = 0;   
    SessionInfo.headerTitle = 'Title';
    SessionInfo.currentPage = '';
    SessionInfo.DomainName = '';
    //------ Logged In Information ---------------------------------------------
    SessionInfo.logonName = '';
    SessionInfo.loggedOn = false;
    SessionInfo.InLogoff = false;
    SessionInfo.forceLogoff = false;
    SessionInfo.logonType = 0;  // 1-logon, 3-Register, 4-token logon, 5-Google Logon, 6-Google Register, 7-SubApp Token Logon, 8-Phone Logon, 9-default logon
    SessionInfo.accountLogon = false;
    SessionInfo.appEntityLogonType = 8; // 1-Normal Logon, 5-Google Logon, 8-Phone Logon
    SessionInfo.logonReturn = 0;
    SessionInfo.appLogonControl = false; // Controlling Logon Type - not sure if this is necessary 
    SessionInfo.AfterSignIn = false;
    SessionInfo.defaultLogon = false;
    SessionInfo.OMUserID = 0;
    SessionInfo.UserName = '';
    SessionInfo.stayLoggedOn = false; // Store cookie token
    SessionInfo.hasPhoneLogin = true;
    SessionInfo.isPhoneLogin = false;
    SessionInfo.phoneLoginVerify = true; // Send Validation Code
    SessionInfo.showLogonChoice = false; // Otherwise, default to 'NO Login'
    SessionInfo.verificationCode = "";
    SessionInfo.ResetOrder = false;
    //------ Permission Information ---------------------------------------------
    SessionInfo.RoleID = 0;
    SessionInfo.PermLevel = 0; // 490-497
    SessionInfo.ApplicationTypeID = 100; // This will determine what functionality is available - ultimately controlled via permissions - see SessionInfo.AC
    //------ Configuration Information ---------------------------------------------
    SessionInfo.ECV = [];  // Entity Config values
    SessionInfo.AppConfiguration = 0; // Application Config Control                 
    // Control Information   
    SessionInfo.menuAction = undefined;
    SessionInfo.setShowNotify = 0;
    SessionInfo.setShowDelete = 0;
    SessionInfo.setGridRefresh = 0;
    SessionInfo.setGridAdd = 0;
    SessionInfo.setLPaneClose = 0;
    SessionInfo.setLPaneOpen = 0;
    SessionInfo.setRPaneClose = 0;
    SessionInfo.setRPaneOpen = 0;
    SessionInfo.firstDigit = '';
    // Page specific Functions
    SessionInfo.currSaveFunc = undefined;
    SessionInfo.currDeleteFunc = undefined;
    SessionInfo.currClearFunc = undefined;
    SessionInfo.currRefreshGridFunc = undefined;
    SessionInfo.currAddGridRowFunc = undefined;
    SessionInfo.currExcelExportFunc = undefined;
    SessionInfo.currExpandLPane = undefined;
    SessionInfo.currCollapseLPane = undefined;
    SessionInfo.currExpandRPane = undefined;
    SessionInfo.currCollapseRPane = undefined;
    SessionInfo.searchFunc = undefined;
    SessionInfo.setLocationFunc = undefined;
    // Page State Storage 
    SessionInfo.AppMainPage = undefined;      
    SessionInfo.AppMainRef = undefined;
    SessionInfo.ScannerPage = undefined;
    SessionInfo.SettingsPage = undefined;
    SessionInfo.ManagerPage = undefined;
    SessionInfo.LandingPage = undefined;
    SessionInfo.ReviewPurchasePage = undefined;
    SessionInfo.OrderPage = undefined;
    SessionInfo.OrderPageRef = undefined; // Ref - is the actual object: this
    SessionInfo.CheckoutPage = undefined;
    SessionInfo.ReceiptsPage = undefined;
    SessionInfo.ReceiptsPageRef = undefined;
    SessionInfo.OrderItemPage = undefined;
    // Menu Values     
    SessionInfo.ItemDetailSecondLevel = false;
    SessionInfo.FirstLevelStoreItemID = 0;
    SessionInfo.CurrentItem = undefined;
    //SessionInfo.CurrentImage = undefined;
    SessionInfo.PromoStoreItemID = 0;
    SessionInfo.CustomOrderID = 0;
    SessionInfo.IsNewOrder = false; // This is set when a New CustomOrderID is assigned Used in CheckoutPage
    SessionInfo.CurrentOrderCustomerID = 0;   
    SessionInfo.ParentOrderID = 0;
    SessionInfo.StoreName = '';
    SessionInfo.SeatName = SessionInfo.CurrentSeat;
    SessionInfo.SeatCount = 0;
    SessionInfo.PaymentMethodID = 0;
    SessionInfo.PaymentTypeID = "";    
    SessionInfo.OrderType = 0; // 1- Deliver, 2-Pickup, 3-Scanner, 4-Menu OrderInStore, 5-Pre-Order
    SessionInfo.ShowAllOrders = false;
    SessionInfo.PayAllOrders = false;
    SessionInfo.PauseOPMonitor = false; // Control OrderPage Monitor
    SessionInfo.FetchInProcess = false;
    SessionInfo.FetchCount = 0;
    SessionInfo.CancelOrder = false;
    SessionInfo.BillPaymentDone = false;
    SessionInfo.BillPaymentComplete = false;
    SessionInfo.TotalBill = 0;
    SessionInfo.TotalTip = 0;
    SessionInfo.LocationOn = false;
    SessionInfo.StartRegister = true;
    SessionInfo.RelatedOrders = 0;
    SessionInfo.PaidOrders = 0;
    SessionInfo.StoreItems = [];
    SessionInfo.saveStoreItems = [];
    SessionInfo.SelectedItems = [];
    SessionInfo.categories = [];
    SessionInfo.catNames = [];
    SessionInfo.selectedCategories = [];
    SessionInfo.favoriteItems = []; // storeItemIDs
    SessionInfo.menuItems = [];
    SessionInfo.MenuControl = [];
    SessionInfo.MenuControlO = []; // Menu for Order Page
    SessionInfo.MenuControlNQR = []; // No QR Code
    // LayoutCfg Control - See: SessionInfo.AppCfg.ScanAndPay
    SessionInfo.DefaultMenuLayout = 1;
    SessionInfo.MenuLayoutSelection = 1;  
    SessionInfo.HasCategoryImages = true;
    SessionInfo.HasMenuImage = true; // Background Image on Menu (Order Page)
    SessionInfo.HasLandingPage = true;  
    SessionInfo.HasLocationInfo = false;
    SessionInfo.HasGroupMenus = false; // Multiple Menus based on ProductItemGroup
    SessionInfo.RetailConfiguration = false;
    // Note - this is the background color for the Menu List - Note the opacity is set to a fraction to let the image show through
    SessionInfo.orderBGColor = 'rgba(240, 240, 240, 0.75)';
    SessionInfo.CopyCustomOrderID = 0;
    SessionInfo.CopyOrderToCurrent = false;
    SessionInfo.ItemCount = 0;
    SessionInfo.RefreshOrder = false;
    SessionInfo.refreshCustomerProfile = false;
    SessionInfo.VisibilityEvent = false;
    SessionInfo.selectedKey = 0;
    SessionInfo.browserPageHasFocus = false;
    SessionInfo.execMonitor = false; // Note - this is for ALL monitors and is controlled by page Focus
    SessionInfo.firstOrderComplete = false;
    SessionInfo.scanQRCode = false;
    SessionInfo.GuestCount = 0;
    SessionInfo.PayTheseOrders = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    // Payment Card Processing
    SessionInfo.PaymentProc = "B";  // F-Full,P-Postal,S-Short, X-Extra Small, B-Bambora - Note - for B - See: SessionInfo.bamboraOffline & SessionInfo.InternalPaymentProcess  
    SessionInfo.InternalPaymentProcess = true; // This is used to determine payment process - True for internal, False for Bambora - See: SessionInfo.bamboraOffline & SessionInfo.InternalPaymentProcess   
    SessionInfo.bamboraOffline = true; // if true - Bambora not available - fake payment - see SessionInfo.PaymentProc & SessionInfo.InternalPaymentProcess
    SessionInfo.bamboraCodeLoaded = false; // Code Loaded - Note - See: Payment Card Processing below - SessionInfo.BamProc
    SessionInfo.BamToken = '';
    SessionInfo.BamProc = false;
    SessionInfo.BamPayProc = undefined; // The Bambora Token String script loaded from their site
    SessionInfo.GetBamboraTokenFirst = false;
    if (SessionInfo.PaymentProc === "B") {
      SessionInfo.InternalPaymentProcess = false; 
      SessionInfo.GetBamboraTokenFirst = false;
      SessionInfo.bamboraOffline = false;
    }
    // Register
    SessionInfo.FirstName = '';
    SessionInfo.LastName = '';
    SessionInfo.Email = '';
    SessionInfo.PhoneNumber = '';
    SessionInfo.Password = '';
    SessionInfo.ConfirmPassword = '';
    SessionInfo.FullName = ''; // FirstName + LastName - See SessionInfo.PersonInfo.CustomerID for CustomerID
    SessionInfo.HoursForToday = '';
    // Key Selections
    SessionInfo.SelectLocationID = 0;
    SessionInfo.Width = 0;
    SessionInfo.Height = 0;
    // Configuration Values
    SessionInfo.AllowPartial = false;
    SessionInfo.RestaurantURL = "https://omnovos.com";
    SessionInfo.LogoImage = undefined;
    SessionInfo.LogoImageURL = undefined;
    SessionInfo.BackgroundImageURL = undefined;
    // Colours
    SessionInfo.barBack = "#eaeefb";  // Option Bar
    SessionInfo.barHilight = "#6985e2"; // Option background Color
    SessionInfo.backHilight = "#ffffb3"; // Tip Amount background Color
    SessionInfo.activeColor = "#3e80ed"; // Menu Color
    SessionInfo.headingTextColor = "#ffffff"; // Heading Text Color
    SessionInfo.searchColor = "black";
    SessionInfo.addColor = "#800000" //"#33cc33"; // Cart Add Color - Set to Brand Color 
    SessionInfo.buttonColor = "#800000" //"#33cc33"; // Cart Add Color
    SessionInfo.removeColor = "#3e80ed";  // Cart Remove Color
    SessionInfo.dietColor = "#33cc33"; // Diet Indicator Color
    SessionInfo.attnColor = "#33cc33"; // Promotion Color
    SessionInfo.quickColor = "#cc0066"; // Order Quick Add
    SessionInfo.inputTextColor = "#3e80ed";
    // Branding                                         
    SessionInfo.brandColor1 = "#993300"; // Brand Color1
    // Note - see commoncode for Logoff
    SessionInfo.SLV = [];  // Hold StoreLocation values  
    SessionInfo.SIV = [];  // Hold StoreItem values
    SessionInfo.GeoFenceMeters = 0;
    SessionInfo.DefaultGeoLocation = '49.24629,-123.1162';
    SessionInfo.GeoLocation = 0;
    SessionInfo.LatLong = { lat: 49.247,lng: -123.116 };
    SessionInfo.DefaultZoom = 14; 
    SessionInfo.BlockTrace = "GetCustomOrderTotal,GetCustomOrderItems,GetAllCustomOrderNames,GetAllCustomOrderTotal,GetImage,PingApp";
    SessionInfo.PersonInfo = { // Customer Info - Version 1.01 - Source: Menu
      CustomerID: 0,
      FirstName: undefined,
      AdditionalName: '',
      LastName: '',
      Address: '',
      City: '',
      ProvinceStateID: 0,
      CountryID: 0,
      PostalCode: '',
      DateOfBirth: undefined,
      Gender: '',
      PhoneNumber: '',
      PhoneNumber2: '',
      EmailAddress: '',
      EmailReceipts: false,
      EmailPromotions: false,
      SMSReminders: false,
      SoundOn: false,
      PersonPhotoID: 0,
      RoleID: 0,
      TotalPurchaseAmount: 0,
      MaximumPurchaseAmount: 0,
      TodayPurchaseAmount: 0,
      LastTimeActive: undefined,
      LastLogonDate: undefined,
    };
    SessionInfo.MenuTree = undefined;
    SessionInfo.PersonPhotoURL = undefined;
    SessionInfo.TestVal = 0;  
    SessionInfo.AC = { // Application Control - Note that this is maintained by hand (mostly)
      HasManagerPage: true,
      HasLandingPage: true,
      HasScanAndPay: true, // Turn on Scan and Pay if AppCfg.ScanAndPay is set to true
      HasReceipts: true,
      HasMyAccount: true,
      HasHelp: true,
      HasLogoff: true,
      HasReservations: false,
      // The following are (currently) set to false by ApplicationTypeID 
      HasOrderType: true,
      HasTable: false,  // Show the Table Name on the Order Header
      HasGuest: true,
      HasViewSeats: true,
      // Menu Control
      HasShoppingCart: true,
      HasTotals: true,
      HasQuickAdd: false,
      HasPromotion: false, // Note - turn off promotions - 6Aug24
      HasDetail: true,
      HasFavorites: true,
      HasDietControl: false,
    };                     
    //------ Encoded Bit Values For ApplicationConfiguration -------
    SessionInfo.AppCfg = {
      ScanAndPay: false,
      OrderAndPay: false,
      ScanPayIndividualItems: false,
      HasIngredients: false,
      HasOrderToTable: false,
      HasDelivery: false,
      HasTip: false,
      HasItemLookup: false,
      HasRecipeLookup: false,
      HasStorePromotion: false,
      HasAccountInfo: false,
      HasGiftCards: false,
      HasCoupons: false,
      HasShoppingList: false,
      CategoryPage: false,
      HasPartialPayment: false,
      HasSeats: false,
      HasGuestInvite: false,
      HasDiscounts: false,
      HasCredit: false,
      HasGooglePay: false,
      HasReturns: false,
      HasTax1: false,
      HasTax2: false,
      HasTax3: false,
      HasWorkflow: false,
      HasCashPayment: false,
      HasVoidOrder: false,
      HasVoidItem: false,
      LayoutConfiguration: 0, // See setLayoutConfigurationBitEncoded in CommonCode
    }             
  }           
  state = { loading: true, }
  callParms = {};
  parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      if (!pair[2]) // a third component - split on = as in an encoded strong
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || ''); // note - decodeURIComponent - translates encoded characters (eg. %27) back to characters (eg. ')
      else
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] + '=' + pair[2] || ''); // note - decodeURIComponent - translates encoded characters (eg. %27) back to characters (eg. ')
    }
    return query;
  }
  requireAuth(nextState,replace) {
    trace(`check Session in requireAuth`);
    if (!SessionInfo || !SessionInfo.session || SessionInfo.session === '') {
      trace(`No Session in navigate`);
      replace({
        pathname: '/login'
      })
    }
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  //setTimer() {
  //  if (SessionInfo.loggedOn === true)
  //    this.timeout = setTimeout(this.monitorProcess.bind(this), 60000); // 1 Minute  
  //}
  //monitorProcess() {
  //  if (SessionInfo.loggedOn === true) {
  //    this.setTimer();
  //    QueryPing();
  //  }
  //}
  // <Route exact path='/HorizontalSlider' element={<HorizontalSlider />} />
  // Note - no match added
  render() {
    //trace('render now');
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<AppMain />} />
          <Route exact path='/AppMain' element={<AppMain />} />
          <Route exact path='/Login' element={<Login />} />
          <Route path='/ScannerPage' element={<ScannerPage />} onEnter={this.requireAuth} />
          <Route path='/OrderPage' element={<OrderPage />} onEnter={this.requireAuth} />
          <Route path='/CheckoutPage' element={<CheckoutPage />} onEnter={this.requireAuth} />
          <Route path='/ReceiptsPage' element={<ReceiptsPage />} onEnter={this.requireAuth} />
          <Route path='/AboutPage' element={<AboutPage />} onEnter={this.requireAuth} />
          <Route path='/ContactUs' element={<ContactUs />} onEnter={this.requireAuth} />
          <Route path='/ProfilePage' element={<ProfilePage />} onEnter={this.requireAuth} />
          <Route path='/ManagerPage' element={<ManagerPage />} onEnter={this.requireAuth} />
          <Route path='/LandingPage' element={<LandingPage />} onEnter={this.requireAuth} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    )
  }
}