import { getter } from "@progress/kendo-react-common";
import { SessionInfo } from './App';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const card4Regex = new RegExp(/^(?:4\d{3}|5[1-5]\d{2}|6011|3[47]\d{2})([- ]?)\d{4}\1\d{4}\1\d{4}$/);
const cvvRegex = new RegExp(/^[0-9]+$/);
//const expiryRegex = new RegExp(/^[0-9]+$/);
const expiryRegex = new RegExp(/^(0[1-9]|1[0-2])\/(2[2-9]|3[0-9])$/);
export const termsValidator = (value) =>
  value ? "" : "It's required to agree with Terms and Conditions.";
export const emailValidator = (value) =>
  !value
    ? "Email field is required."
    : emailRegex.test(value)
      ? ""
      : "Email is not in a valid format.";
export const nameValidator = (value) =>
  !value
    ? "Full Name is required"
    : value.length < 7
      ? "Full Name should be at least 7 characters long."
      : "";
export const userNameValidator = (value) =>
  !value
    ? "User Name is required"
    : value.length < 5
      ? "User name should be at least 5 characters long."
      : "";
export const phoneValidator = (value) =>
  !value
    ? "Phone number is required."
    : phoneRegex.test(value)
      ? ""
      : "Not a valid phone number.";
export const card3Validator = (value) =>
  !value
    ? "Credit card number is required. "
    : testCard(value)
      ? ""
      : "Not a valid credit card number format.";
export const cardValidator = (value) =>
  !value
    ? "Credit card number is required. "
    : testCard(value)
      ? ""
      : "Not a valid credit card number format.";
export const ccvValidator = (value) =>
  !value
    ? "CVC code is required,"
    : cvvRegex.test(value) || value.length !== 3
      ? ""
      : "Not a valid CCV code format.";
export const expiryValidator = (value) =>
  !value
    ? "Expiry Date is required."
    : testDate(value)
      ? ""
      : "Not a valid expiry date.";
export const guestsValidator = (value) =>
  !value ? "Number of guests is required" : value < 5 ? "" : "Maximum 5 guests";
export const nightsValidator = (value) =>
  value ? "" : "Number of Nights is required.";
export const arrivalDateValidator = (value) =>
  value ? "" : "Arrival Date is required.";
export const colorValidator = (value) => (value ? "" : "Color is required.");
export const requiredValidator = (value) =>
  value ? "" : "Error: This field is required.";
export const passwordValidator = (value) =>
  value && value.length > 8 ? "" : "Password must be at least 8 symbols.";
export const addressValidator = (value) =>
  value ? "" : "Address is required.";
const userNameGetter = getter("username");
const emailGetter = getter("email");
export const formValidator = (values) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);
  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }
  return {
    VALIDATION_SUMMARY: "Please fill in the following fields.",
    ["username"]: !userName ? "User Name is required." : "",
    ["email"]:
      emailValue && emailRegex.test(emailValue)
        ? ""
        : "Email is required and should be in a valid format.",
  };
};
const testCard = (val) => {
  let ret = card4Regex.test(val);
  if (ret) {
    let firstDigit = val.toString().substring(0,1);
    console.log(`firstDigit: ${firstDigit}, ret: ${ret}`);
    if (firstDigit === '0' || firstDigit === '9') {
      ret = false;
      SessionInfo.firstDigit = '';
    }
    else
      SessionInfo.firstDigit = firstDigit;
  }
  return ret;
}
const testDate = (val) => {
  let ret = expiryRegex.test(val);
  if (ret) {
    let year = val.toString().substring(3,5);
    let yearint = parseInt(year);
    if (yearint < 24)
      ret = false;                     
    //console.log(`date: ${val}, year: ${year}, ret: ${ret}`);
  }
  return ret;
}