//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ContactUs - Interact Suite Contact Message
//                      Version 1.007 - June 5, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { SessionInfo } from './App';
import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import { TreeView } from '@progress/kendo-react-treeview';
import { Input } from '@progress/kendo-react-inputs';
import { FloatingLabel } from '@progress/kendo-react-labels'
import { CallOM,trace,CTYP,TYP } from './CommonCode.js';
import { ENAppCnfg } from './CommonInfo.js';

import { ReactComponent as MenuBar } from "./images/bars.svg";

const QuestionsOID = 544162;
const QuestionsO = [{ n: 'QuestionID', t: 24, p: 100 }, { n: 'Name', t: 21, p: 0 }, { n: 'EmailAddress', t: 21, p: 0 }, { n: 'QuestionTypeID', t: 24, p: 100 }, { n: 'MessageDetail', t: 21, p: 0 }, { n: 'OMUserID', t: 24, p: 100 }, { n: 'IPAddress', t: 21, p: 0 }];

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    if (!SessionInfo.session || SessionInfo.session === '')
      return;
  }
  menuAnchor = React.createRef();
  state = {
    QuestionID: 0,
    Name: '',
    EmailAddress: '',
    QuestionTypeID: '',
    MessageDetail: '',
    showMenu: false,
    StoreName: '',
    TableName: '',
    SeatCount: 0,
    SeatName: '',
    ItemCount: 0,
    NotifyMessage: '',
  };
  currentType = '';
  refreshCount = 6;
  compUpdate = true;
  componentDidMount() {
    if (!SessionInfo.session || SessionInfo.session === '')
      return;
    this.setState({ Name: SessionInfo.FullName });
    this.setState({ EmailAddress: SessionInfo.logonEmailAddress });
    SessionInfo.currentPage = "ContactUs";
    this.setState({ QuestionID: 0 });
    this.setState({ StoreName: SessionInfo.StoreName });
    this.setState({ TableName: SessionInfo.CurrentTableName });
    this.setState({ SeatName: SessionInfo.SeatName });
    this.setState({ SeatCount: SessionInfo.SeatCount });
    this.setState({ ItemCount: SessionInfo.ItemCount });
    this.setState({ NotifyMessage: '' });
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    //let value = this.context; 
  }
  shouldComponentUpdate() {
    //trace("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  openMenu = () => {
    //this.setState({ showItemsDetail: false });
    //this.keepMenu = true;
    this.setState({ showMenu: true });
    trace(`open Menu - showMenu: ${this.state.showMenu}`);
  }
  onMenuItemClick = async (event) => {
    trace(`menu event Item: ${event.item.text}`);
    if (SessionInfo.currentItem !== undefined) {
      //trace('Current Item: ' + SessionInfo.currentItem.text);
      SessionInfo.currentItem.selected = false;
    }
    SessionInfo.currentItem = event.item;
    trace('2 Current Item: ' + SessionInfo.currentItem.text);
    event.item.selected = true;
    event.item.expanded = true;
    //trace(`Event - name: ${JSON.stringify(event.item)}`);
    SessionInfo.MenuSelect = event.item;
    this.keepMenu = false;
    this.setState({ showMenu: false });
    if (SessionInfo.menuAction !== undefined) {
      await SessionInfo.menuAction(); // onMenuItemClick in AppMain.js
    }
    this.forceUpdate();
  }
  submitMessage = async () => { // Get Transaction Receipt
    try {
      let isTemp = true;
      let copyState = Object.assign({},this.state);
      let iType = 0;
      for (let prop in copyState) {
        //trace("Prop: " + prop + " val: " + copyState[prop]);
        if (prop === "QuestionTypeID") {
          let cType = copyState[prop];
          this.currentType = cType;
          //trace(`cType: ${cType}`);
          switch (cType) {
            case "Add To Project":
              this.currentType = "Request to be added to a Project";
              iType = 1;
              break;
            case "Bug":
              this.currentType = "Bug Report";
              iType = 2;
              break;
            case "Enhancement":
              this.currentType = "Enhancement Request";
              iType = 3;
              break;
            case "Help":
              this.currentType = "Help Information";
              iType = 4;
              break;
            case "Issue":
              iType = 5;
              break;
            case "Other":
              this.currentType = "Other? Input";
              iType = 6;
              break;
            //case "Permission Add":
            //  this.currentType = "Request to add a Permission";
            //  iType = 7;
            //  break;
            case "Question":
              iType = 8;
              break;
            case "Request":
              iType = 9;
              break;
            default:
              iType = 0;
              break;
          }
          if (iType === 0) {
            this.notify("A Contact Type must be selected ");
            return;
          }
          copyState[prop] = iType;
          //trace(`iType: ${iType}, copyState: ${copyState[prop]}`);
        }
      }
      if (copyState.EmailAddress == '') {
        this.notify(`Please add your email address`);
      } else if (copyState.MessageDetail !== '') {
        copyState.OMUserID = SessionInfo.OMUserID;
        copyState.QuestionTypeID = iType;
        trace("copystate: " + JSON.stringify(copyState));
        let CD = await CallOM("AddQuestionInfo",0,0,13,copyState);
        trace(`Call Return CD: ${JSON.stringify(CD)}`);
        if (CD.x.o === 0)
          await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
        else if (CD.x.o < 9500) {
          let QuestionID = CD.d;
          this.notify(`Thank you for your input. (${this.state.QuestionTypeID}) - Reference#: ${QuestionID}`);
          this.setState({ QuestionID });
          this.clearRecord();
        }
      }
      else
        this.notify(`Please add a Message`);
      this.forceUpdate();
    } //try
    catch (error) {
      trace(error);
    } //catch
  } //submitMessage

  notify = (msg) => {
    this.setState({ NotifyMessage: msg });
  }
  clearRecord = async () => { // Note => functions do not bind their owner to this - if used clearRecord() - would have to bind this to the function
    //trace("Clear Screen - state: " + JSON.stringify(this.state)); 
    QuestionsO.forEach((elem) => {
      let sobj = {};
      try {
        if (elem.t === 34 || elem.t === 35)
          sobj[elem.n] = new Date();
        else if (elem.t === 21 || elem.t === 22)
          sobj[elem.n] = '';
        else
          sobj[elem.n] = 0;
        this.setState(sobj);
      } catch (error) {
        trace("Clear Item Field: " + elem.n + " Error: " + error.message);
      }
    });
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }
  updateType(evt) {
    trace("Type: " + evt.target.value);
    this.setState({ QuestionTypeID: evt.target.value });
  }
  render() {
    return (
      <div id="ContactUs" className="pageMain">

        <div id="hdr" className="header">
          <div className="headerLogoLeft">
            {SessionInfo.HasLandingPage ?
              <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }} onClick={(e) => this.gotoLanding()} />
              :
              <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }} href={SessionInfo.RestaurantURL} target="_blank" rel="noopener noreferrer" />}
          </div>
          <div className="headerCenter">
            <div className="headerCenterMenu">
              <MenuBar alt="" fill={SessionInfo.headingTextColor} id="MenuBtn" title={`View Menu`} className="functionImageL" onClick={(e) => this.openMenu()} ref={this.menuAnchor} />
            </div>
            <div className="headerCenterTop">
              <span className='landingHeadTextLarge'>&nbsp;&nbsp;&nbsp;Contact Us</span>
              <br />
            </div>
          </div>   
          <br />
          <p style={{ fontSize: '18px',padding: '10px' }}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Have a question or an idea?</p>
        </div>

        <Popup anchor={this.menuAnchor.current} show={this.state.showMenu} popupClass={'popupMenu'} >
          <div className="mainMenu">
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
            <div className="menuTree">
              {SessionInfo.AppCfg.HasScanAndPay === false ?
                <br /> : null}
              {SessionInfo.defaultLogon === true ?
                <br /> : null}
              <TreeView
                data={SessionInfo.MenuControlNQR}
                size='large' draggable='false' selection='single'
                onItemClick={this.onMenuItemClick}
                className='menuTreeSub'
                item={props => [<span key={props.item.key} className={props.item.className}>{props.item.icon}{props.item.space}{props.item.text}</span>]}
              />
            </div>
          </div>
        </Popup>

        <div className="contactEdit" >
          <div className="contactBox">
            <div id="div2" className="editField">
              <label style={{ width: '120px',fontSize: '18px',fontWeight: 'normal' }}>Contact Type &nbsp; &nbsp; </label>
              <select name="QuestionTypeID" className="questionType" value={this.state.QuestionTypeID} onChange={evt => this.updateType(evt)}>
                <option>Select...</option>
                <option>Bug</option>
                <option>Enhancement</option>
                <option>Help</option>
                <option>Issue</option>
                <option>Other</option>
                {/*<option>Permission Add</option>*/}
                <option>Question</option>
                <option>Request</option>
              </select>
            </div>
            <div id="div2" className="editFieldW">
              <span className="editFieldLabel" style={{ fontSize: '18px',fontWeight: 'normal' }}>Email/Phone</span>
              <Input name="EmailAddress" value={this.state.EmailAddress} className="editInput" style={{ height: '30px',left: '160px' }} onChange={evt => this.chgFldVal(evt)} />
            </div>
            <div id="div2" className="editField">
              <label style={{ fontSize: '18px' }}>Your Message</label>
              <br />
              <textarea name="MessageDetail" cols="" rows="30" className="editInputTA" style={{ width: '97%',height: '200px',padding: '5px',fontSize: '16px',resize: 'none', borderRadius: '15px' }} value={this.state.MessageDetail} onChange={evt => this.chgFldVal(evt)}></textarea>
              <label style={{ fontSize: '14px' }}>*Please provide a full description</label>
              <br />
              <br />
              <Button id="sendMsgButton" icon="redo" look="outline" className="largeButton" onClick={this.submitMessage} >&nbsp;Send Message &nbsp;</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs