//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// shopping Cart icon and navigation  -   Version 1.00 - July 25, 2024
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRef,useEffect,useState } from 'react';
import './App.css';
import { Input } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { LogOn,LogOff,UserRegister,RequestPWReset,trace,AddressLogOn,GetTableFilter,MaskString,UnmaskString,LogonWithPhone,SendVerifyCode,LogonWithPhoneVerify,setCookie,getCookie } from './CommonCode.js';
import { SessionInfo } from './App';
                                                  
import { ReactComponent as CartDisplay } from "./images/cart.svg";
import { ReactComponent as CartEmptyDisplay } from "./images/cartempty.svg"; 

export const ShoppingCart = (props) => {  
  const navigate = useNavigate();
  const gotoCart = async (event) => {
    if (props.count > 0)
      navigate("/CheckoutPage");
  }                
  //console.log(`----------->> TEST - props: ${props}`);
  //console.log(props);
  return (   
    <div style={{ all: 'initial' }}>
      <div style={{ position: 'absolute' }}>
      {props.count > 0 ?
        <>
          <CartDisplay alt="" stroke={props.stroke} id="ChooseItemBtn" title={`Go To Checkout`} className="cartImage" onClick={(e) => gotoCart(e)} />
          {props.count < 10 ?
            <div style={{ position: 'absolute',fontSize: '14px',top: '2px',right: '6px',color: 'white',fontWeight: 'bold',zIndex: '5',cursor: 'pointer' }} onClick={(e) => gotoCart(e)}>{props.count}</div> :
            <div style={{ position: 'absolute',fontSize: '14px',top: '2px',right: '2px',color: 'white',zIndex: '5' }} onClick={(e) => gotoCart(e)}>{props.count}</div>}
        </> :
          <CartEmptyDisplay alt="" stroke={props.stroke} id="ChooseItemBtn" className="cartImage" onClick={(e) => gotoCart(e)} />}
        </div>
    </div>
  );
}