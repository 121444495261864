//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// CommonCode - Code to support working with React in conjunction with Telerik Controls and the omUIapi and general helper functions
//              Version 1.3 - 28 October 2021
// Note - this is the Checkout Version of CommonFormat
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';
import { SessionInfo } from './App';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { formatNumber } from '@progress/kendo-intl';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { CallOM,OMTrace,trace,displayMessage,CTYP,TYP,NOTIFY,SetNotify } from './CommonCode.js';
//----------------------- Format Classes - Version 1.01 -------------------------------------------------------------------------------------------------------------------------
export const DayOfWeekDD = [{ ddName: 'Sunday',ddID: 0 },{ ddName: 'Monday',ddID: 1 },{ ddName: 'Tuesday',ddID: 2 },{ ddName: 'Wednesday',ddID: 3 },{ ddName: 'Thursday',ddID: 4 },{ ddName: 'Friday',ddID: 5 },{ ddName: 'Saturday',ddID: 6 },{ ddName: 'Sunday',ddID: 7 }];
export const Months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]; 
export class DayOfWeekDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //trace("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //trace("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;                                            
    //trace("Val: " + dataValue + " DayOfWeek Cell: " + JSON.stringify(DayOfWeekDD));
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (DayOfWeekDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {DayOfWeekDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={DayOfWeekDD} textField="ddName" dataItemKey="ddID" value={DayOfWeekDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetTimeOverrideTypes(DD) {
  TimeOverrideTypeDD = DD;
}
export var TimeOverrideTypeDD = [{ ddName: '', ddID: 0 }];
export class TimeOverrideDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //trace("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //trace("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    //trace("Val: " + dataValue + " DayOfWeek Cell: " + JSON.stringify(DayOfWeekDD));
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (DayOfWeekDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {TimeOverrideTypeDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={TimeOverrideTypeDD} textField="ddName" dataItemKey="ddID" value={TimeOverrideTypeDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export const TimePickerCell = (props) => {
  let value = props.dataItem[props.field]
  //trace("Field: " + props.field + " value: " + value);
  if (!value)
    value = new Date("0001-01-01T08:00:00");
  //trace(" Field: " + props.field + " date value: " + new Date(value.toString()));
  const handleChange = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  }
  if (props.dataItem.inEdit) {
    return (
      <td>
        <TimePicker value={new Date(value)} onChange={handleChange} />
      </td>
    )
  } else {
    return (
      <td>
        <TimePicker value={new Date(value)} />
      </td>
    )
  }
}
export const OrderNumCell = (props) => {
  let value = props.dataItem[props.field];
  return (
    <td>#
      {value}
    </td>
  )
}

export const SeatCell = (props) => {
  const SeatName = props.dataItem[props.field];
  trace(`SeatName: ${SeatName}, Session: ${SessionInfo.CurrentSeat}`);
  const currSeat = SeatName === SessionInfo.SeatName;

  const style = {
    backgroundColor: currSeat ?
      "rgb(194, 240, 194)" :
      "rgb(255, 255, 255)"
  };
  return (
    <td style={style}>
      {props.dataItem[props.field]}
    </td>
  );
}
export const PercentCell = (props) => {
  let value = props.dataItem[props.field];
  const handleChange = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  }
  if (props.dataItem.inEdit) {
    return (
      <td>
        <NumericTextBox value={value} format={"p1"} onChange={handleChange} step={0.01} />
      </td>
    )
  } else {
    return (
      <td>
        {formatNumber(value, "p1")}
      </td>
    )
  }
}
export const MoneyCell = (props) => {
  let value = props.dataItem[props.field];
  const handleChange = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  }
  if (props.dataItem.inEdit) {
    return (
      <td>
        <NumericTextBox value={value} format={"c2"} onChange={handleChange} />
      </td>
    )
  } else {
    return (
      <td>
        {formatNumber(value, "c2")}
      </td>
    )
  }
}
export const BoolCell = (props) => {
  trace("checkbox:  inEdit: " + JSON.stringify(props.dataItem));
  let value = props.dataItem[props.field];
  value = (value === true || value === 'true'); 
  const handleChange = (e) => {
    trace("checkbox: " + value + " e.target.val: " + e.target.value + " inEdit: " + props.dataItem.inEdit);
    //trace("onchange checkbox:  inEdit: " + JSON.stringify(props.dataItem));
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: !value //e.target.value
    });
  }
  if (props.dataItem.inEdit) {
    return (
      <td>
        <input type="checkbox" checked={value} style={{ color: "darkgray", width: 36, height: 36 }} onChange={handleChange} />
      </td>
    )
  } else {
    return (
      <td>
        <input disabled type="checkbox" checked={value} style={{ color: "darkblue", width: 36, height: 36 }} onChange={handleChange}/>
      </td>
    )
  }
}
export const ImageCell = (props) => {
  //trace(`ImageCell - ${JSON.stringify(props)}`);
  if (!props.dataItem.imageValFld) {
    let value = props.dataItem[props.field];
    if (value === undefined) { // This is blank
      value = "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAZABkDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD32iiigAooooAKKKKACiiigD//2Q==";
    }
    const byteCharacters = atob(value);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    let image = new Blob([byteArray], { type: 'image/jpeg' });
    //trace("image: " + JSON.stringify(image));
    props.dataItem.imageValFld = URL.createObjectURL(image);
    //trace("ImageCell 2-value: " + value);
  }

  const handleChange = (e) => {
    //trace("imagecell: " + value + " e.target.val: " + e.target.value);
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  }
  if (props.dataItem.inEdit) {
    return (
      <td>
        {/* <Avatar shape='circle' type='img'>*/}
        <img alt="" src={props.dataItem.imageValFld} onChange={handleChange} />
      </td>
    )
  } else {
    return (
      <td>
        <img alt="" disabled src={props.dataItem.imageValFld} />
      </td>
    )
  }
}

export class cellDateTime extends React.Component {
  render() {                          
    const minDate = new Date(2018, 9, 9);
    return (
      <td >
        {!this.props.dataItem[this.props.field] || Date.parse(this.props.dataItem[this.props.field]) < minDate ? "" : new Intl.DateTimeFormat('en-CA', { dateStyle: 'long', timeStyle: 'short' }).format(Date.parse(this.props.dataItem[this.props.field]))}
      </td>
    );
  }
}
export class cellCurrency extends React.Component {
  render() {
    return (
      <td >
        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.dataItem[this.props.field])}
      </td>
    );
  }
}
export class cellCurrencyAmt extends React.Component {
  render() {
    const amountGreater = this.props.dataItem[this.props.field] > 15;

    const style = {
      backgroundColor: amountGreater ?
        "rgb(243, 23, 0, 0.32)" :
        "rgb(55, 180, 0,0.32)"
    };

    //moneyFormat = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    return (
      <td style={style}>
        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.dataItem[this.props.field])}
      </td>
    );
  }
}
export class cellOptions extends React.Component {
  render() {
    const optionsNonZero = this.props.dataItem[this.props.field] > 0;

    const style = {
      backgroundColor: optionsNonZero ?
        "rgb(55, 180, 0,0.32)" :
        "white"
    };

    return (
      <td style={style}>
        {this.props.dataItem[this.props.field]}
      </td>
    );
  }
}
export class cellQuantity extends React.Component {
  render() {
    const optionsNonZero = this.props.dataItem[this.props.field] > 1;

    const style = {
      backgroundColor: optionsNonZero ?
        "rgb(55, 180, 0,0.32)" :
        "white"
    };

    return (
      <td style={style}>
        {this.props.dataItem[this.props.field]}
      </td>
    );
  }
}
//--------------------- EDIT Classes ----------------------------------------------------------
export const EditGridCommandCell = props => {
  //trace(`Edit CC: editField: ${props.editField}, keyField: ${props.keyField}`);
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem[props.keyField] === undefined;
  return inEdit ? (
    <td className="k-command-cell">
      <button className="k-button k-grid-save-command" onClick={() => (isNewItem ? props.add(dataItem) : props.update(dataItem))}>
        {isNewItem ? "Add" : "Update"}
      </button>
      <button className="k-button k-grid-cancel-command" onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))}>
        {isNewItem ? "Discard" : "Cancel"}
      </button>
    </td>
  ) : (
      <td className="k-command-cell">
        <button className="k-primary k-button k-grid-edit-command" onClick={() => props.edit(dataItem)}>
          Edit
      </button>
        <button className="k-button k-grid-remove-command" onClick={() => window.confirm("Confirm Delete: '" + dataItem[props.nameField] + "'") && props.remove(dataItem)}>
          Remove
      </button>
      </td>
    );
};
//-----------------------------------------------------------------------------------------------
export var PricingTypesDD = [{ ddName: '', ddID: 0 }];
export class PricingTypeDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //trace("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //trace("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    //trace("PricingTypes Cell: " + JSON.stringify(PricingTypesDD));
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (PricingTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {PricingTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={PricingTypesDD} textField="ddName" dataItemKey="ddID" value={PricingTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetPricingType(DD) {
  PricingTypesDD = DD;
}
//                                                                    
//-----------------------------------------------------------------------------------------------
export var RateTypesDD = [{ ddName: '', ddID: 0 }];
export class RateTypeDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //trace("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //trace("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    //trace("RateTypes Cell: " + JSON.stringify(RateTypesDD));
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (RateTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {RateTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={RateTypesDD} textField="ddName" dataItemKey="ddID" value={RateTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetRateType(DD) {
  RateTypesDD = DD;
}
//
export var ProductItemCategorysDD = [{ ddName: '', ddID: 0 }];
export class ProductItemCategoryDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //trace("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //trace("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (ProductItemCategorysDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ProductItemCategorysDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "120px" }} data={ProductItemCategorysDD} textField="ddName" dataItemKey="ddID" value={ProductItemCategorysDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetProductItemCategory(DD) {
  ProductItemCategorysDD = DD;
}
export var ProductItemGroupsDD = [{ ddName: '', ddID: 0 }];
export class ProductItemGroupDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //trace("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //trace("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (ProductItemGroupsDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ProductItemGroupsDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "120px" }} data={ProductItemGroupsDD} textField="ddName" dataItemKey="ddID" value={ProductItemGroupsDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetProductItemGroup(DD) {
  ProductItemGroupsDD = DD;
}
//
export var ProductTypesDD = [{ ddName: '', ddID: 0 }];
export class ProductTypeDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    //trace("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //trace("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (ProductTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ProductTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "120px" }} data={ProductTypesDD} textField="ddName" dataItemKey="ddID" value={ProductTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetProductTypes(DD) {
  ProductTypesDD = DD;
}
//--
export var ItemAssociationTypesDD = [{ ddName: '', ddID: 0 }];
export class ItemAssociationTypeDropDownCell extends React.Component {
  handleChange = (e) => {
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (ItemAssociationTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ItemAssociationTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "160px" }} data={ItemAssociationTypesDD} textField="ddName" dataItemKey="ddID" value={ItemAssociationTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
export function SetItemAssociationTypes(DD) {
  ItemAssociationTypesDD = DD;
}
//---------------------------------------------------------------------------------------------
export function GetMonth(monthN) {
  return Months[monthN];
}
export function GetDay(dayN) {
  return DayOfWeekDD.find(c => c.ddID === dayN).ddName;
}
//---------------------------------------------------------------------------------------------
export function SetNotificationTypes(DD) { NotificationTypesDD = DD; }
export var NotificationTypesDD = [{ ddName: '', ddID: 0 }];
export class NotificationTypeDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem)); 
    this.props.onChange({ dataItem: this.props.dataItem, field: this.props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
    //trace("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //trace("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    //trace("PricingTypes Cell: " + JSON.stringify(PricingTypesDD));
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (NotificationTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {NotificationTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={NotificationTypesDD} textField="ddName" dataItemKey="ddID" value={NotificationTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---------------------------------------------------------------------------------------------
export function SetStoreLocations(DD) {
  StoreLocationsDD = DD;
  trace("StoreLocationsDD: " + JSON.stringify(StoreLocationsDD));
}
export var StoreLocationsDD = [{ ddName: '', ddID: 0 }];
export class StoreLocationDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({ dataItem: this.props.dataItem, field: this.props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
    //trace("change data before: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    //trace("change data after: " + JSON.stringify(this.props.dataItem[this.props.field]));
    this.forceUpdate();
  }
  render() {
    //trace("StoreLocation Types Cell: " + JSON.stringify(StoreLocationsDD));
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (StoreLocationsDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {StoreLocationsDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "200px" }} data={StoreLocationsDD} textField="ddName" dataItemKey="ddID" value={StoreLocationsDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---------------------------------------------------------------------------------------------
export function SetLocations(DD) { LocationsDD = DD; }
export var LocationsDD = [{ ddName: '', ddID: 0 }];
export class LocationDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    this.props.onChange({ dataItem: this.props.dataItem, field: this.props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (LocationsDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {LocationsDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "200px" }} data={LocationsDD} textField="ddName" dataItemKey="ddID" value={LocationsDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---------------------------------------------------------------------------------------------
//SublocationTypesDropDownCell, SetSublocationTypes                          
export function SetSublocationTypes(DD) { SublocationTypesDD = DD; }
export var SublocationTypesDD = [{ ddName: '', ddID: 0 }];
export class SublocationTypesDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    this.props.onChange({ dataItem: this.props.dataItem, field: this.props.field, syntheticEvent: e.syntheticEvent, value: e.target.value });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (SublocationTypesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {SublocationTypesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "200px" }} data={SublocationTypesDD} textField="ddName" dataItemKey="ddID" value={SublocationTypesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//---------------------------------------------------------------------------------------------
export function SetProductType(DD) { ProductTypesDD = DD; }
export let ItemCategoriesDD = [];
export class CategoryDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (dataValue === undefined)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (ItemCategoriesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ItemCategoriesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={ItemCategoriesDD} textField="ddName" dataItemKey="ddID" value={ItemCategoriesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
  //---------------------------------------------------------------------------------------------  
  // Sample code
  //---------------------------------------------------------------------------------------------
  //is = (fileName, ext) => new RegExp(`.${ext}\$`).test(fileName);
  //conClassName = (text, items) => {
  //  if (items !== undefined) {
  //    return 'k-icon k-i-folder';
  //  } else if (is(text, 'pdf')) {
  //    return 'k-icon k-i-file-pdf';
  //  } else if (is(text, 'html')) {
  //    return 'k-icon k-i-html';
  //  } else if (is(text, 'jpg|png')) {
  //    return 'k-icon k-i-image';
  //  } else {
  //    return '';
  //  }
  //}
  //---------------------------------------------------------------------------------------------
}