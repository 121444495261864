import React from 'react'
import { GoogleMap,useJsApiLoader } from '@react-google-maps/api';
import { SessionInfo } from './App';
//import { trace } from './CommonCode.js';

const containerStyle = {
  width: '100%',
  height: '200px'
};
// See: https://react-google-maps-api-docs.netlify.app/
function MyGoogleMap() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBfCCwbCYQTfRjymntWxr__yuK85ypvRag"
  })
  //const options = {
  //  zoomControlOptions: {
  //    position: google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
  //    // ...otherOptions
  //  }
  //}

  const [map,setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    console.log(`onLoad - latlong: ${JSON.stringify(SessionInfo.LatLong)}`);
    const bounds = new window.google.maps.LatLngBounds(SessionInfo.LatLong);
    //map.fitBounds(bounds);
    map.setZoom(SessionInfo.DefaultZoom)

    setMap(map)
  },[])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  },[])

  // See setup in api/setup.js
  const OPTIONS = {
    minZoom: 6,    // Works
    maxZoom: 18,   // Works
    ControlPosition: 13,
    DirectionsTravelMode: 'WALKING',
    MapTypeControlStyle: 2,
    MapTypeId: 'roadmap',    // Works  hybrid, satellite, terrain
    DirectionsRenderer: 'setDirections',
    Aminimation: 1,
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={SessionInfo.LatLong}
      zoom={SessionInfo.DefaultZoom}
      options={OPTIONS}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      { /* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : <></>
}

export default React.memo(MyGoogleMap)