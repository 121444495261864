import React, { } from 'react';
//import { useNavigate } from 'react-router-dom';
import { withRouter } from './withRouter';        
import { SessionInfo } from './App';
import { Avatar } from '@progress/kendo-react-layout';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { FloatingLabel } from '@progress/kendo-react-labels'
import { Popup } from '@progress/kendo-react-popup';
import { TreeView } from '@progress/kendo-react-treeview';
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';
//import DOMPurify from 'dompurify';
import { CallOM,OMTrace,trace,GetFileExtension,CreateKeys,LogOffResetMsg,shortDesc,TYP,CTYP } from './CommonCode.js';
import { ENAppCnfg, ENLayoutCnfg } from './CommonInfo.js';

import { ReactComponent as MenuBar } from "./images/bars.svg";
import { ReactComponent as Favorite } from "./images/star.svg";
import { ReactComponent as FavoriteSelect } from "./images/menu-favorite.svg";
import { ReactComponent as ButtonSearch } from "./images/search.svg";
import { ReactComponent as ButtonClear } from "./images/button-clear.svg";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
  }
  menuAnchor = React.createRef();
  state = {
    MessagesValue: '',
    currentTime: '',
    showMenu: false,
    showDetail: false,
    helpDetail: '',
    HelpPages: [],
    searchValue: '',
    lastSearchValue: '',
    clearSetAll: false,
  };
  detailOffset = { left: 5, top: 10 };
  refreshCount = 6;
  async componentDidMount() {
    if (!SessionInfo.session || SessionInfo.session === '')
      this.props.navigate("/AppMain");
    else {
      SessionInfo.currentPage = "AboutPage";
      await this.getHelpSummary(this.state.searchValue);
      this.setState({ StoreName: SessionInfo.StoreName });
      this.setState({ TableName: SessionInfo.CurrentTableName });
      this.setState({ SeatName: SessionInfo.SeatName });
      this.setState({ SeatCount: SessionInfo.SeatCount });
      this.setState({ ItemCount: SessionInfo.ItemCount });
    }
  }
  getHelpTopics = async (search) => {
    try {
      if (!search)
        search = '';
      //trace(`Get Help Pages`);
      let helpType = 11; // Menu App Help
      let CD = await CallOM("GetHelpPages",helpType,0,CTYP.STR,search);
      //trace(`Get Return - o: ${CD.x.o}`);
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`, 1);
      else if (CD.x.o < 9500) {
        if (CD && CD.d) {
          try {
            //trace("Help Items: " + JSON.stringify(CD));
            let HelpPages = CD.d.rows.map(dataItem => Object.assign({ isFavorite: false, selected: false }, dataItem));
            //trace("Help Items: " + JSON.stringify(HelpPages));
            this.setState({ HelpPages });
          } catch (error) {
            await OMTrace(2,`Failure in getHelpTopics - Error: ${error}`);
          }
        }
      }
    } catch (error) {
      await OMTrace(2,`Failure (2) in getHelpTopics - Error: ${error}`);
    }
  }
  getHelpSummary = async (search) => {
    try {
      if (!search)
        search = '';
      //trace(`Get Help Pages`);
      let helpType = 11; // Menu App Help
      let CD = await CallOM("GetHelpSummary",helpType,0,CTYP.STR,search);
      //trace(`Get Return - o: ${CD.x.o}`);
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o < 9500) {
        if (CD && CD.d) {
          try {
            //trace("Help Items: " + JSON.stringify(CD));
            let HelpPages = CD.d.rows.map(dataItem => Object.assign({ isFavorite: false,selected: false },dataItem));
            //trace("Help Items: " + JSON.stringify(HelpPages));
            this.setState({ HelpPages });
          } catch (error) {
            await OMTrace(2,`Failure in getHelpSummary - Error: ${error}`);
          }
        }
      }
    } catch (error) {
      await OMTrace(2,`Failure (2) in getHelpSummary - Error: ${error}`);
    }
  }
  //---------------------------------------------------------------------------------------------------------
  openMenu = () => {
    //this.setState({ showItemsDetail: false });
    //this.keepMenu = true;
    this.setState({ showMenu: true });
    trace(`open Menu - showMenu: ${this.state.showMenu}`);
  }
  onMenuItemClick = async (event) => {
    trace(`menu event Item: ${event.item.text}`);
    if (SessionInfo.currentItem !== undefined) {
      //trace('Current Item: ' + SessionInfo.currentItem.text);
      SessionInfo.currentItem.selected = false;
    }
    SessionInfo.currentItem = event.item;
    trace(`2 Current Item: ${SessionInfo.currentItem.text}`);
    event.item.selected = true;
    event.item.expanded = true;
    //trace(`Event - name: ${JSON.stringify(event.item)}`);
    SessionInfo.MenuSelect = event.item;
    this.keepMenu = false;
    this.setState({ showMenu: false });
    if (SessionInfo.menuAction !== undefined) {
      await SessionInfo.menuAction(); // onMenuItemClick in AppMain.js
    }
    this.forceUpdate();
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
  onHelpItemClick = async (item) => {
    trace(`Help Item: ${item.HelpPageID}`);
    let CD = await CallOM("GetHelpPage", 0, 0, 1, item.HelpPageID);
    trace(`Get Return - o: ${CD.x.o}`);
    let helpDetail = "x";
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`, 1);
    else if (CD.x.o < 9500) {
      if (CD && CD.d) {
        helpDetail = CD.d;
      }
    }
    this.setState({ helpDetail });
    this.setState({ showDetail: true });
  }
  closePopup = async (event) => {
    this.setState({ showDetail: false });
  }
  //------------------------- Search ---------------------------------------------------------------------------------------------------------------------------------------------
  searchButton = async (event) => {
    trace(`search Button: ${this.state.searchValue}`);
    event.preventDefault();
    event.stopPropagation();
    //await this.searchCurrent(this.state.searchValue);
    await this.getHelpSummary(this.state.searchValue);
  }
  updateSearchValue = async (evt) => { // Original Search Value event handler
    let newValue = evt.value;
    //trace(`searchValue: ${this.state.searchValue}, targetname: ${evt.name}, event.value: ${evt.target.value}, ${newValue}`);
    if (!(newValue.length > 0 && (newValue[0] === '*' || newValue[0] === '`'))) {
      if (newValue.length >= 2 || this.state.lastSearchValue.length > newValue.length) {
        this.setState({ lastSearchValue: newValue });
        await this.getHelpSummary(newValue);
      }
      else {
        this.setState({ lastSearchValue: newValue });
      }
    }
    this.setState({ searchValue: newValue }); // Note - moved save to State to last operation
  }
  async chgSrchVal (event) {
    this.setState({ searchValue: event.target.value });
    this.setState({ stateHasChanged: true });
    trace(`searchValue: ${this.state.searchValue}, targetname: ${event.target.name}, event.value: ${event.target.value}`);
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
    if (!(event.target.value.length > 0 && (event.target.value[0] === '*' || event.target.value[0] === '`'))) {
      if (event.target.value.length >= 2 || this.state.lastSearchValue.length > event.target.value.length) {
        this.setState({ lastSearchValue: event.target.value });
        await this.getHelpSummary(event.target.value);
      }
      else {
        this.setState({ lastSearchValue: event.value });
      }
    }
  }
  async chgTAFldVal(event) {
    trace(`searchValue: ${this.state.searchValue}, targetname: ${event.target.name}, event.value: ${event.value}`);
    this.setState({ [event.target.name]: event.value });
    this.setState({ stateHasChanged: true });
    if (!(event.value.length > 0 && (event.value[0] === '*' || event.value[0] === '`'))) {
      if (event.value.length >= 2 || this.state.lastSearchValue.length > event.value.length) {
        let newValue = event.value;
        this.setState({ lastSearchValue: newValue });
        await this.getHelpSummary(newValue);
      }
      else {
        this.setState({ lastSearchValue: event.value });
      }
    }
  }
  itemSearch = (srchStr) => { // Search for Match
    //trace(`ItemSearch - search: ${srchStr}`);
    //trace(`saveStoreItems: ${JSON.stringify(SessionInfo.saveStoreItems)}`);
    this.closePopups(3);
    if (srchStr.length > 0) {
      let StoreItems = [];
      let cnt = 0;
      let srch = new RegExp(srchStr, "i");
      let cats = [];
      this.state.Categories.forEach(cat => {
        if (cat.ProductItemCategory.search(srch) >= 0)
          cats.push(cat.ProductItemCategoryID);
      });
      SessionInfo.saveStoreItems.forEach(row => {
        //trace(`search idx: ${row.ItemName.search(srchStr)}, name: ${row.ItemName}, ItemDesc: ${row.ItemDescription.search(srchStr)}, Desc: ${row.ItemDescription}`);
        let found = false;
        if (cats.length > 0) {
          cats.forEach(catID => {
            if (found == true)
              return;
            if (row.ProductItemCategoryID == catID) {
              StoreItems.push(row);
              cnt++
              found = true;
            }
          });
        }
        if (found == false) {
          if (row.ItemName.search(srch) >= 0) {
            StoreItems.push(row);
            cnt++
          } else if (row.ItemDescription.search(srch) >= 0) {
            StoreItems.push(row);
            cnt++
          }
        }
      });
      //trace(`category match: ${cnt}`);
      this.lastCategory = "";
      if (cnt === 0) {
        this.setState({ StoreItems: SessionInfo.saveStoreItems });
      } else
        this.setState({ StoreItems });
    } else { // ALL
      this.setState({ StoreItems: SessionInfo.saveStoreItems });
    }
  }
  clearSearch = async (event) => { // ClearDisplay, Clear Messages - see updateSearchValue
    //trace(`clear search: ${this.state.searchValue}`);
    if (this.state.searchValue !== '') {
      await this.getHelpSummary('');
      this.setState({ searchValue: '' });
      this.setState({ lastSearchValue: '' });
      this.setState({ clearSetAll: true });
      this.setState({ stateHasChanged: true });
    }
  }
  //------------------------- Menu Page List Template ----------------------------------------------------------------------------------------------------------------------------
  HelpPageHeader = () => {
    return (
      <ListViewHeader className='listHelpHeader'>
        <span style={{ fontSize: 22, fontWeight: 'bold', color: 'black' }}>&nbsp; &nbsp; Interact Menu Help Information </span>
      </ListViewHeader>
    );
  }
  HelpPageFormat = props => {
    let item = props.dataItem;
    //if (!item.imageVal) {
    //  let value = item.Image;
    //  if (!value) { // Note - blank
    //    value = "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAZABkDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD32iiigAooooAKKKKACiiigD//2Q==";
    //  }
    //  const byteCharacters = atob(value);
    //  const byteNumbers = new Array(byteCharacters.length);
    //  for (let i = 0; i < byteCharacters.length; i++) {
    //    byteNumbers[i] = byteCharacters.charCodeAt(i);
    //  }
    //  const byteArray = new Uint8Array(byteNumbers);
    //  let image = new Blob([byteArray], { type: 'image/jpeg' });
    //  item.imageVal = URL.createObjectURL(image);
    //}

    return (
      <div>
        <div className="listHelpRow" onClick={(e) => this.onHelpItemClick(item)}>
          <div className="listColA">
            <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{item.HelpTitle}&nbsp;&nbsp;&nbsp;
            </span><br />
            <div dangerouslySetInnerHTML={{ __html: item.HelpContent }}></div>
          </div>
        </div>
      </div>
    );
  }
  HelpPageFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listHelpFooter'>
      </ListViewFooter>
    );
  }

  render() {
    return (
      <div id="AboutPage" className="pageMainFx">
        <div id="hdr" className="header">
          <div className="headerLogoLeft">
            {SessionInfo.HasLandingPage ?
              <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }} onClick={(e) => this.gotoLanding()} />
              :
              <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }} href={SessionInfo.RestaurantURL} target="_blank" rel="noopener noreferrer" />}
          </div>
          <div className="headerCenter">
            <div className="headerCenterMenu">
              <MenuBar alt="" fill={SessionInfo.headingTextColor} id="MenuBtn" title={`View Menu`} className="functionImageL" onClick={(e) => this.openMenu()} ref={this.menuAnchor} />
            </div>
            <div className="headerCenterTop">
              <span className='landingHeadTextLarge'>&nbsp;&nbsp;&nbsp;Help Information</span>
              <br />
            </div>
            <div className="headerCenterMidH">
              {SessionInfo.traceDebug ?
                <span className='landingHeadTextSmall'>&nbsp; &nbsp; &nbsp;V: {SessionInfo.appVersion}-{SessionInfo.Platform}&nbsp;&nbsp;</span> : <span>&nbsp;&nbsp;</span>}
            </div>
          </div>

          <div className="headerMiddleLeft">
            <div id="headerSearch">
              <div id="searchBtnDiv">
                <ButtonSearch alt="" fill={SessionInfo.searchColor} className="functionImage" onClick={this.searchButton} />
              </div>
              <div id="searchBoxDiv">
                <FloatingLabel id={"srchLabelID"} label='Search Help' style={{ width: '100', color: 'darkblue' }} editorId={'search-id'} editorValue={this.state.searchValue}>
                  <Input id={'searchID'} value={this.state.searchValue} ariaLabelledBy={"srchLabelID"} autoComplete="off" onChange={evt => this.updateSearchValue(evt)} className='searchInput' />
                </FloatingLabel>
              </div>
              <div id="clearSearchBtnDiv">
                <ButtonClear alt="" fill={SessionInfo.searchColor} className="functionImage" onClick={this.clearSearch} />
              </div>
            </div>
          </div>
        </div>

        <Popup anchor={this.menuAnchor.current} show={this.state.showMenu} popupClass={'popupMenu'} >
          <div className="mainMenu">
            {SessionInfo.HasLandingPage ?
              <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} onClick={(e) => this.gotoLanding()} />
              :
              <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href={SessionInfo.RestaurantURL} target="_blank" rel="noopener noreferrer" />}
            <div className="menuTree">
              {SessionInfo.AppCfg.HasScanAndPay === false ?
                <br /> : null}        
              <TreeView
                data={SessionInfo.MenuControlNQR}
                size='large' draggable='false' selection='single'
                onItemClick={this.onMenuItemClick}
                className='menuTreeSub'
                item={props => [<span key={props.item.key} className={props.item.className}>{props.item.icon}{props.item.space}{props.item.text}</span>]}
              />
            </div>
          </div>
        </Popup>
        <div className="aboutList">
          <ListView data={this.state.HelpPages} item={this.HelpPageFormat} header={this.HelpPageHeader} footer={this.HelpPageFooter} />
        </div>
        <Popup offset={this.detailOffset} show={this.state.showDetail} popupClass={'popupAboutDetail'} >
          <div className="frameInfo" id="notifyBoxPU" dangerouslySetInnerHTML={{ __html: this.state.helpDetail }} onClick={this.closePopup}></div>
        </Popup>

      </div>
    );
  }
}

export default withRouter(AboutPage)